<template>
  <section>
    <v-container>
      <div id="return" class="my-6">
        <v-card class="rounded-lg" flat>
          <v-card-title>
            Return Policy Overview
          </v-card-title>
          <v-card-text>
            At Sea2Sea Scuba, we strive to offer the best possible products and customer service to ensure your satisfaction. While we do not accept returns, we understand that certain circumstances may require attention. Please read our return policy carefully to understand our guidelines.
            <br>
            <br>
            No Returns:
            <br>
            As a general rule, all sales made at Sea2Sea Scuba are final. We do not accept returns on any products, as we are committed to providing high-quality items that meet our customers' needs.
            <br>
            <br>
            Store Credit:
            <br>
            In the event that a customer is unsatisfied with their purchase from our in-store retail center, we are happy to offer store credit for the item's value. The customer must provide the original receipt, and the item must be in its original, unused condition with all packaging materials intact. Store credit will be issued within 14 days of the original purchase date.
            <br>
            <br>
            Online Transactions:
            <br>
            In the rare circumstance where a product purchased online has been misrepresented or the details were not accurately provided, we will review the case and may authorize a return. To be eligible for a return, the customer must notify us within 7 days of receiving the item, providing details and evidence of the issue. After assessing the case, we will contact the customer with a resolution, which may include a return, exchange, or store credit.
            <br>
            <br>
            Positive Resolutions:
            <br>
            At Sea2Sea Scuba, we understand that our customers' satisfaction is our top priority. We are always ready to work with you to find a positive resolution to any issue you may encounter with your purchase. Please do not hesitate to contact us with any concerns or inquiries.
            <br>
            <br>
            By making a purchase at Sea2Sea Scuba, you acknowledge and agree to adhere to the terms and conditions outlined in this return policy. Thank you for your understanding and for choosing Sea2Sea Scuba for your underwater adventures!
          </v-card-text>
        </v-card>
      </div>
      <div id="privacy" class="my-6">
        <v-card class="rounded-lg" flat>
          <v-card-title>
            Online Privacy Overview
          </v-card-title>
          <v-card-text>
            Our website, Sea2Sea, utilizes cookies to enhance your browsing experience and ensure smooth navigation throughout the site. These cookies are small text files placed on your device that store data about your preferences and interactions with our site.
            <br>
            <br>
            There are two primary categories of cookies that we use: necessary cookies and third-party cookies. Necessary cookies are stored on your browser and play a crucial role in enabling basic functionalities on the website, such as page navigation and access to secure areas. These cookies are indispensable for the proper functioning of our site and cannot be opted out of without affecting your browsing experience.
            <br>
            <br>
            In addition to necessary cookies, we may also employ third-party cookies to help us analyze and comprehend how visitors interact with our website. These cookies gather information about your usage patterns, including pages visited, time spent on each page, and other engagement metrics. Data collected by these cookies is stored in your browser and can be disabled by modifying your browser's settings.
            <br>
            <br>
            Please note that our website relies on both cookies and JavaScript to function optimally. Disabling cookies or JavaScript may result in a subpar browsing experience or limited access to certain features.
            <br>
            <br>
            We prioritize the responsible collection and handling of user information, which may include your active IP address, device details, current route, estimated location, time spent on route, scroll position, past activity, and any voluntarily provided personal details. Rest assured that all payment processing information is securely managed by our trusted partner, Stripe, ensuring the safety and privacy of your financial data.
            <br>
            <br>
            If you have any concerns or inquiries about your data on Sea2Sea, please feel free to contact our customer support team during our business hours. We are committed to addressing your concerns and maintaining transparency about our data collection practices.
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'policy',
  mounted () {
    this.$vuetify.goTo(`#${this.$route.params.scrollTo}`, {
      duration: 500,
      offset: 0
    })
  },
  created () {
    this.doSetDocumentTitle('Policies', true)
  }
}
</script>

<style scoped>

</style>
